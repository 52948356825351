<template>
  <main class="page-search-result">
    <div id="printContainer" class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>搜尋結果</el-breadcrumb-item>
        </el-breadcrumb>
        <h4>搜尋結果</h4>
        <section v-loading="isPageLoading">
          <!-- 側邊選單 -->
          <ul class="list list--aside">
            <li
              class="item"
              :key="item.label"
              v-for="item in sideMenuData">
              <router-link
                class="link"
                :class="item.class"
                :to="item.routerTo">
                {{item.label}} ({{item.doc_count}})
              </router-link>
            </li>
          </ul>
          <!-- 後分類選單 -->
          <div class="separate-title"></div>
          <TheFilterPanel>
          </TheFilterPanel>
        </section>
        <section>
          <RecentQuery v-if="isShowRecentQuery">
          </RecentQuery>
        </section>
      </div>
      <div class="container">
        <div
          class="container-hint"
          v-if="isPageLoading==false">
          <div>
            <!-- {{getterSearchConditionSummary}} -->
            <el-popover
              trigger="click"
              width="500">
              <p
                v-for="(text,index) in getterSearchConditionList"
                :key="index">
                {{text}}
              </p>
              <!-- <p>關鍵字：宜誠資訊股份有限公司</p>
              <p>公司類型：ＸＸＸ</p> -->
              <el-button slot="reference" type="text" size="mini">查看目前搜尋條件</el-button>
            </el-popover>
          </div>
          <div
            style="font-size:10px;color:#909399;margin-top:8px"
            v-if="companyCount>10000">
            為讓您享有最佳體驗，至多顯示查詢結果前 10,000 則資訊
          </div>
        </div>
        <div
          v-if="isPageLoading==false&&companyCount<=0">
          <el-button type="primary" icon="el-icon-arrow-left" @click="goHome">點此返回查詢首頁</el-button>
        </div>
        <div
          class="company-list-set"
          style="min-height:100%"
          v-if="isPageLoading||(isPageLoading==false&&companyCount>0)"
          v-loading="isPageLoading||isCompanyListLoading">
          <div class="company-list-toolbar">
            <div class="toolbar-left">
              <el-select
                size="mini"
                placeholder="排序"
                v-model="companyListSort"
                @change="onCompanyListSortChange">
                <el-option
                  v-for="item in companyListSortOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="toolbar-right">
              <el-pagination
                layout="prev, pager, next, jumper"
                :total="companySearchableCount"
                :current-page.sync="currentPage"
                @current-change="searchCompanyList">
              </el-pagination>
            </div>
          </div>
          <CompanyList
            style="min-height:100%"
            :companyList="companyList"
            :queryCondition="currentSearchQuery"
            :isCollections="isCollections">
          </CompanyList>
          <div class="align-right">
            <el-pagination
              layout="prev, pager, next, jumper"
              :total="companySearchableCount"
              :current-page.sync="currentPage"
              @current-change="searchCompanyList">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <MainPageFloatButton
      :downloadAction="downloadAction">
    </MainPageFloatButton>
  </main>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import recentQueryStorage from '@/storage/recentQueryStorage.js'
import routerService from '@/utils/routerService.js'
import utils from '@/utils/utils.js'
import companyListSortOptions from '@/const/companyListSortOptions.js'
import CompanyList from '@/components/CompanyList/CompanyList.vue'
import TheFilterPanel from '@/components/TheFilterPanel/TheFilterPanel.vue'
import RecentQuery from '@/components/RecentQuery/RecentQuery.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'
import parseCompanyListExcel from '@/utils/downloadExcel/parseCompanyListExcel.js'
import parseCompanyListBatchExcel from '@/utils/downloadExcel/parseCompanyListBatchExcel.js'

function getDataTypeArray (dataTypeIndex) {
  if (dataTypeIndex === codes.DATA_TYPE_INDEX_DIST['全部']) {
    return ['公司', '商業登記']
  } else if (dataTypeIndex === codes.DATA_TYPE_INDEX_DIST['公司']) {
    return ['公司']
  } else {
    return ['商業登記']
  }
}

export default {
  props: {
    // query.params
    'dataTypeIndex': {
      default () {
        // return codes.DATA_TYPE_INDEX_DIST[codes.DEFAULT_DATA_TYPE]
        return 'default'
      }
    }
  },
  components: {
    CompanyList,
    TheFilterPanel,
    RecentQuery,
    MainPageFloatButton
  },
  data () {
    return {
      isShowRecentQuery: false,
      companyListSortOptions,
      companyListSort: codes.DEFAULT_COMPANY_LIST_SORT_VALUE,
      currentQuery: {},
      recentQuery: [],
      filterSampleCheckbox: [],
      companyTags: [
        '紡織',
        '傳產',
        '創新研發'
      ],
      addNewTagInput: '',
      addNewTagInputVisible: false,
      currentPage: 1
      // isPageLoading: false,
      // isCompanyListLoading: false,
    }
  },
  computed: {
    ...mapState([
      'originRouteQuery'
    ]),
    ...mapState('companyList', [
      'currentSearchQuery',
      // 'originSearchQuery',
      'companyList',
      'companyCount',
      'isCollections',
      'companySearchableCount',
      'isPageLoading',
      'isCompanyListLoading',
      // 'sidebarMenu'
    ]),
    ...mapGetters('companyList', [
      'getterSearchConditionSummary',
      'getterSearchConditionList',
      'getterSidebarMenu',
      'getteBatchFileName'
    ]),
    currentDataType () {
      if (this.dataTypeIndex === codes.DATA_TYPE_INDEX_DIST['全部']) {
        return '全部'
      } else if (this.dataTypeIndex === codes.DATA_TYPE_INDEX_DIST['公司']) {
        return '公司'
      } else {
        return '商業登記'
      }
    },
    sideMenuData () {
      return this.getterSidebarMenu.map(data => {
        const searchRequest = JSON.parse(this.originRouteQuery.currentQuery)
        searchRequest.basic.data_type_array = getDataTypeArray(data.dataTypeIndex)
        searchRequest.basic['page-index'] = 1
        return {
          label: data.label,
          doc_count: data.doc_count,
          dataTypeIndex: data.dataTypeIndex,
          class: { active: data.label === this.currentDataType },
          routerTo: {
            name: 'companyList',
            params: {
              dataTypeIndex: data.dataTypeIndex
            },
            query: {
              originQuery: this.originRouteQuery.originQuery,
              currentQuery: JSON.stringify(searchRequest)
            }
          }
        }
      })
    }
  },
  methods: {
    ...mapActions('companyList', [
      'actionInitSearchPage',
      'actionSearchPage',
      // 'actionCompanyList'
    ]),
    goHome () {
      // this.$router.push({ name: 'home' })
      routerService('push', { name: 'home' })
    },
    onCompanyListSortChange (sortValue) {
      let searchRequest = JSON.parse(JSON.stringify(this.currentSearchQuery))
      searchRequest.basic['sort'] = JSON.parse(sortValue)
      searchRequest.basic['page-index'] = 1
      this.currentPage = searchRequest.basic['page-index'] // 更新頁碼

      // 更新網址
      let query = JSON.parse(JSON.stringify(this.originRouteQuery))
      query.currentQuery = JSON.stringify(searchRequest)
      // this.$router.replace({
      //   name: 'companyList',
      //   query
      // })
      routerService('push', {
        name: 'companyList',
        query
      })
    },
    // 查詢公司列表（切換頁碼）
    searchCompanyList (pageNo) {
      let searchRequest = JSON.parse(JSON.stringify(this.currentSearchQuery))
      searchRequest.basic['page-index'] = pageNo
      this.currentPage = searchRequest.basic['page-index'] // 更新頁碼

      // 更新網址
      let query = JSON.parse(JSON.stringify(this.originRouteQuery))
      query.currentQuery = JSON.stringify(searchRequest)
      // this.$router.replace({
      //   name: 'companyList',
      //   query
      // })
      routerService('push', {
        name: 'companyList',
        query
      })
    },
    // 下載
    async downloadAction () {
      try {
        let searchQuery = JSON.parse(JSON.stringify(this.currentSearchQuery))
        searchQuery.basic.get = this.getteBatchFileName
          // 批次查詢
          ? ['xlsx-rows-full']
          // 非批次查詢
          : ['list']
        searchQuery.basic['page-index'] = 1
        searchQuery.basic['page-size'] = 10000

        const result = await apis.companyListGet(searchQuery)
        if (!result.success || !result.payload || !result.payload.list) {
          return false
        }

        await import('@/vendor/Export2Excel.js').then(excel => {

          const { data, header } = this.getteBatchFileName
            // 批次查詢
            ? parseCompanyListBatchExcel(result.payload['xlsx-rows-full'])
            // 非批次查詢
            : parseCompanyListExcel(result.payload.list)

          excel.export_json_to_excel({
            header, //表头 必填
            data, //具体数据 必填
            filename: `${moment().format('YYYY_MM_DD_HH_mm')}`, //非必填
            autoWidth: true, //非必填
            bookType: 'xlsx' //非必填
          })

          return
        })

        return true
      } catch (e) {
        console.error(e)
      }
    }
  },
  async created () {
    // 將首頁設定的查詢條件紀錄起來
    if (this.originRouteQuery && this.originRouteQuery.currentQuery) {

      // 開啟頁面初次查詢
      let searchRequest = JSON.parse(this.originRouteQuery.currentQuery)
      this.currentPage = searchRequest.basic['page-index'] || 1 // 更新頁碼
      await this.actionInitSearchPage(searchRequest)

      // -- 紀錄最近查詢 --
      try {
        let keyword = ''
        if (searchRequest.basic && searchRequest.basic.keyword) {
          keyword = searchRequest.basic.keyword
        } else {
          return // 排除掉無關鍵字的查詢
        }
        // 存入localStorage
        recentQueryStorage.insert(keyword, this.$route)
        // 顯示
        this.isShowRecentQuery = true
      }
      catch (e) {
      }
    }
  },
  watch: {
    '$route.query': {
      async handler (routeQuery) {
        if (routeQuery.query) {
          // 將query decode並進行查詢
          const result = await apis.decode({ key: routeQuery.query })
          const queryDecode = JSON.parse(result.value)
          const searchRequest = JSON.parse(queryDecode.currentQuery)
          this.currentPage = searchRequest.basic['page-index'] || 1 // 更新頁碼
          this.actionSearchPage(searchRequest)
        } else if (routeQuery.originQuery) {
          // 透過左側選單切換時，因為按鈕上的網址參數是原始參數，所以要重新導向到短網址
          // 網址參數還沒encode的話，先encode
          routerService('replace', {
            name: 'companyList',
            query: routeQuery
          })
        }
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.dataTypeIndex) {
        routerService('replace', { name: 'home' })
        return
      }
      // 如果網址參數是原始參數的話，重新再導向到短網址
      if (vm.$route.query.originQuery && vm.$route.query.currentQuery) {
        routerService('replace', {
          name: 'companyList',
          params: vm.$route.params,
          query: vm.$route.query
        })
        return
      }
      if (!vm.$route.query.query) {
        routerService('replace', { name: 'home' })
        return
      }
    })
  }
}
</script>
