import moment from 'moment'
import { parseBaseRow } from './parseBaseCompanyListExcel'
import { getPortAndIncomeTime } from './parseBaseCompanyListExcel'

export default function (list) {

  const { port, income } = getPortAndIncomeTime()

  // 取得所有「對應公司統編」並攤平成陣列
  const machedList = list
    .map(row => {
      return row.對應公司統編.map((item => {
        return {
          // -- 批次查詢原始資料 --
          公司名稱: row.公司名稱,
          公司統編: row.公司統編,
          查詢結果筆數: row.查詢結果筆數,
          附註: row.附註,
          // -- 對應到的資料 --
          ...item
        }
      }))
    })
    .flat()

  const header = [
    // -- 批次查時詢時才有的欄位 --
    '附註',
    '公司名稱(原)',
    '公司統編(原)',
    // -- 以下和一般查詢的下載欄位一樣 --
    '公司名稱(結果)',
    '公司英文名稱',
    '統一編號',
    '發行狀態',
    '代表人',
    '公司所在地址',
    '公司所在地址(英文)',
    '電話',
    'email',
    '資本總額(元)',
    '實收資本總額(元)',
    '公司狀況',
    '核准設立日期',
    '營業項目',
    `${port.lastOneYear}進口實績`,
    `${port.lastTwoYear}進口實績`,
    `${port.lastThreeYear}進口實績`,
    `${port.lastOneYear}出口實績`,
    `${port.lastTwoYear}出口實績`,
    `${port.lastThreeYear}出口實績`,
    // '電子郵件',
    // '登記機關',
    '營業狀況',
    `${income.lastOneYear}營業收入`,
    `${income.lastTwoYear}營業收入`
  ]

  const data = machedList.map((row, i) => {
    const baseRow = parseBaseRow(row, port, income)

    return [
      row.附註 || '',
      row.公司名稱 || '',
      row.公司統編 || '',
      ...baseRow
    ]
  })

  return {
    header,
    data
  }
}